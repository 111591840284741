import React from "react"
import { Link, graphql } from "gatsby"
import HeaderList from "../components/header/headerList"
import Header from "../components/header/header"
import Footer from "../components/footer"
import slugify from "slugify"
import { FaMapMarkerAlt } from "react-icons/fa"

const CategoryList = ({
  pageContext: { service },
  data: { allMarkdownRemark, allFile },
  location,
}) => {
  const formatCover = image => {
    return (
      allFile &&
      allFile.edges.filter(e => e.node.name === image)[0].node.publicURL
    )
  }
  const datas = allMarkdownRemark.edges.map(f => {
    return {
      node: {
        frontmatter: {
          categories: f.node.frontmatter.categories,
          date: f.node.frontmatter.date,
          imageCover: f.node.frontmatter.imageCover
            ? formatCover(f.node.frontmatter.imageCover)
            : null,
          service: f.node.frontmatter.service,
          title: f.node.frontmatter.title,
          place: f.node.frontmatter.place,
          serviceDetail: f.node.frontmatter.serviceDetail,
        },
        id: f.node.id,
      },
    }
  })

  return (
    <div>
      <Header location={location} />
      {/* <HeaderList pathname={location.pathname}/> */}
      <div className="pt-8 pb-8 text-center uppercase text-2xl font-bold">
        {service}
      </div>
      <p className="max-w-4xl mx-auto pb-8 italic text-justify px-6">
        {datas[0].node.frontmatter.serviceDetail}
      </p>
      <div className="bg-white">
        <div className="max-w-4xl mx-auto">
          <div className="box-content grid grid-cols-1 md:grid-cols-2 gap-4 pl-4 pr-4 pt-8 pb-8">
            {datas.map(({ node }) => (
              <Link
                key={node.id}
                state={{ service }}
                to={`${slugify(node.frontmatter.title.toLowerCase())}`}
              >
                <div className="block md:flex drop-shadow-xl w-100 bg-white rounded-lg">
                  <img
                    className="md:rounded-bl-lg md:rounded-tl-lg rounded-t-lg rounded-b-none w-full md:w-48 h-48 object-cover"
                    src={node.frontmatter.imageCover}
                    alt={"image" + service}
                  />
                  <div className="pl-6 p-4">
                    <h2 className="font-semibold uppercase pb-2">
                      {node.frontmatter.title}
                    </h2>
                    {node.frontmatter.categories.map(categorie => (
                      <div className="inline-flex rounded-full items-center bg-slate-200 p-2 text-xs font-light text-grey mr-2 last:mr-0">
                        <FaMapMarkerAlt className="mr-1" />
                        {node.frontmatter.place}
                      </div>
                    ))}
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
export const query = graphql`
  query ServiceListQuery($category: [String], $service: [String]) {
    allMarkdownRemark(
      filter: {
        frontmatter: {
          service: { in: $service }
          categories: { in: $category }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            imageCover
            title
            place
            service
            categories
            serviceDetail
            date(formatString: "MMM D, YYYY")
          }
          id
        }
      }
    }
    allFile(filter: { extension: { regex: "/(jpg)|(png)|(jpeg)/" } }) {
      edges {
        node {
          publicURL
          name
        }
      }
    }
  }
`

export default CategoryList
