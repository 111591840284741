import React from "react"
import image from "./../../images/logo-bg-white.png"

const Footer = () => {
  return (
    <div className="bg-[#F5F5F7]">
      <div className=" mt-4 max-w-5xl mx-auto px-2 sm:px-6 lg:px-8">
        <div className="flex flex-col sm:flex-row pt-8 pb-5 max-w-5xl mx-auto">
          <div className="flex justify-center mb-2 sm:hidden">
            <img className="block h-24 md:h-32 w-auto" src={image} alt="logo" />
          </div>
          <img
            className="sm:block hidden h-24 md:h-32 w-auto"
            src={image}
            alt="logo"
          />
          <div className="flex flex-col items-center sm:flex-row w-full sm:justify-around sm:items-stretch">
            <div className="flex flex-col mx-2 md:mx-6 ">
              <div>
                <span className="font-bold uppercase">Rénovation Thomy</span> -{" "}
                <span className="italic">
                  Société spécialisée dans la peinture en batiment sur Toulouse
                  et sa périphérie.
                </span>
              </div>
            </div>
            <div className="flex flex-col justify-between sm:w-64">
              <div className="mt-2 mb-2 sm:mt-0 sm:mb-0">
                <div>31700 DAUX</div>
              </div>
              <a
                className="bg-blue-900 block p-3 rounded-full w-fit text-white font-bold text-sm"
                href="tel:+33678104465"
              >
                Appelez moi
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
